import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import EvilDiv from '../generic/EvilDiv'
import Collapse from '@mui/material/Collapse'
import { Button } from '@mui/material'
import Edit from './Edit'
import { env } from '../../../environment'
import { useTranslation } from 'react-i18next'
import { getHash } from '../../utils/Crypto'

export interface MaterialTeaserProps {
  teaserData: any
  teaserBlockName?: string
}

export default function MaterialTeaser(props: MaterialTeaserProps) {
  const { t } = useTranslation('translation')
  const [expanded, setExpanded] = React.useState(false)
  const handleClick = () => {
    setExpanded(!expanded)
  }

  const signature = getHash({ lang: env.getCmsLanguage(), material: props })

  const teaserData = props.teaserData

  let additonalInfo = <></>

  if (teaserData && teaserData.teaser) {
    if (
      typeof props.teaserBlockName !== 'undefined' &&
      props.teaserBlockName !== teaserData.material_key.value &&
      props.teaserBlockName !== '' &&
      process.env.SHOW_CMS_LINKS == '1'
    ) {
      additonalInfo = (
        <p className="devInfo">
          Hier könnte auch Content zum Key *{props.teaserBlockName}* stehen
        </p>
      )
    }

    return (
      <>
        <div key={signature}>
          <EvilDiv
            content={teaserData.teaser.value.childMarkdownRemark.html}
            key={'teaser_pues1'}
          />
          {additonalInfo}
          <Edit
            cockpitId={teaserData.cockpitId}
            description={
              'Material-Key: *' + teaserData.material_key.value + '*'
            }
            type="public_material_teaser"
          />
          {teaserData.teaser_more && (
            <div>
              <Button
                color="primary"
                key={'teaserbutton_pues1'}
                onClick={handleClick}
              >
                {!expanded ? t('Mehr lesen') : t('Weniger')}
              </Button>
              <Collapse
                in={expanded}
                key={'teasermorecollapse_pues1'}
                timeout="auto"
                unmountOnExit
              >
                <EvilDiv
                  content={
                    teaserData.teaser_more.value.childMarkdownRemark.html
                  }
                  key={'teasermore_pues1'}
                />
              </Collapse>
            </div>
          )}
        </div>
      </>
    )
  } else {
    return <></>
  }
}
